<template lang="pug">
.checklist-item
  .checklist-item__body
    app-checkbox(
      :id="item.id"
      :inputValue="item.is_done"
      :value="item.is_done"
      @change="onIsDoneChange"
      v2
    ).checklist-checkbox

    crm-textarea(
      :value="item.name"
      :maxlength="3000"
      :rows="2"
      :placeholder="'Type checkbox name here'"
      @change="onNameChange"
      @blur="cancelEdit"
      :disabled="!editable"
      auto-height
      v2
    ).checkList-textarea

    ico-message(class="mr-2" :class="{'comment-btn-disabled': item.comment.length}" @click.native="toggleCommentField")
    app-popup(v-model="showing" placement="left")
      app-button( icon iconSmall rounded).unselectable
        <i class="material-icons">more_vert</i>
      div(slot="popup")
        .checklist-menu(@click="startEditing" :class="{'checklist-menu_editable': editable, 'checklist-menu_disabled': item.comment.length}") 
          ico-edit().checklist-menu__ico
          | Edit task
        .checklist-menu(@click="removeItem(item)")
          ico-remove.checklist-menu__ico
          | Delete task
  
  .checklist-item__comment(v-if="showCommentField || item.comment.length")
    p(v-if="item.comment.length && !showCommentField")
      b Comment: 
      | {{ item.comment }}
    template(v-if="showCommentField")
      app-input(
        
        v-model="innerComment" 
        :key="formErrors.hasError('comment')"
        :error="formErrors.hasError('comment')"
        :error-messages="formErrors.fields.comment"
        :hide-details="false"
        placeholder="Enter your comment here" 
        :filled="false" 
        label="Comment"
        v2
      )
      div
        app-button(
          @click.native="cancelComment"
          text color="primary" fontSize="10px" small class="ml-1 mr-1") Cancel
        app-button(
          @click.native="saveComment"
          color="primary" fontSize="10px" small
        ) Save

</template>

<script>
import appButton from '@/components/global/actions/BaseButton.vue'
import AppInput from '@/components/global/Input.vue'
import crmTextarea from '@/components/global/actions/BaseTextarea.vue'
import appCheckbox from '@/components/global/Checkbox.vue'
import FormErrors from '@/util/form-errors'
import errorsMixin from '@/mixins/errors.mixin'
import ChecklistItemCommentValidator from "../../core/checkListCommentValidator"
import AppPopup from "@/components/global/BasePopup.vue"

export default {
  mixins: [errorsMixin],
  name: 'checklistITem',
  props: {
    item: {
      required:true,
      type: Object
    }
  },

  data: () => ({
    editable: false,
    showCommentField: false,
    innerComment: '',
    showing: false,
    formErrors: new FormErrors()
  }),

  components: {
    appButton,
    crmTextarea,
    appCheckbox,
    AppInput,
    icoMessage: () => import("@/assets/img/ui/components/IcoMessage.vue"),
    icoEdit: () => import('@/assets/img/ui/crm/IcoEdit.vue'),
    icoRemove: () => import('@/assets/img/ui/crm/IcoRemove.vue'),
    AppPopup

  },

  methods: {

    startEditing() {
      if(this.item.comment.length) return
      this.editable = true
      this.showing = false
    },

    toggleEditable() {
      this.editable = !this.editable
    },
    
    cancelEdit() {
      this.editable = false
    },

    onIsDoneChange(ev) {
      this.$emit('change:is_done', ev)
    },

    onNameChange(ev) {
      this.editable = false
      this.$emit('change:name', ev)
    },

    toggleCommentField() {
      if(this.item.comment.length) return
      this.showCommentField = !this.showCommentField
    },

    cancelComment() {
      this.innerComment = this.item.comment
      this.toggleCommentField()
    },

    saveComment() {
      this.formErrors.resetFields()
      let valid = new ChecklistItemCommentValidator({comment: this.innerComment}, this.formErrors).isValid()
      if(!valid) return
      this.$emit('change:comment', this.innerComment)
      this.toggleCommentField()
    },
    

    removeItem(item) {
      this.showing = false
      this.$emit('remove:item', item)
    }
    
  },

  watch: {
    item: { 
      immediate: true,
      handler: function() {
        this.innerComment = this.item.comment
      }
    }
  }
}
</script>

<style lang="scss"> 
.checklist-item {
  padding: 12px 0 12px 0;
  border-bottom: 1px solid #E5E8EF;

  &__comment {
    display: flex;
    margin-top: 16px !important;
    margin-left: 34px;
    p {
      margin-bottom: 0;
      color: #007AFF;
      font-size: 9px;
    };
  }
  &__body {
    display: flex;
    align-items: center;
  }

  .checkList-textarea {
    display: flex;
    margin: 0;
    padding: 0 15px;
    width: 100%;
    color: $title-second;
    font-size: 14px;

    textarea {
      // min-height: 65px;
    }
  }

  .checklist-checkbox {
    .checkbox__wrapper {
      height: 16px;
    }

    input {
      width: 16px;
      height: 16px;

      &::after {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }

      &:checked::after {
        border-radius: 50%;
      }
    }

    .checkbox__checked {
      width: 16px;
      height: 16px;

      .material-icons {
        width: 10px;
      }
    }
  }
}

.comment-btn-disabled {
  color: #E5E8EF;
}

.checklist-menu {
  display: flex;
  align-items: center;
  padding: 6px;
  font-weight: 500;
  font-size: 10px;
  font-family: $font;
  cursor: pointer;

  &:hover {
    background-color: #F2F4F7;
  }
  &__ico {
    margin-right: 4px;
    width: 10px;
    color: #161616;
  }
  &_editable {
    background-color: #F2F4F7;
    color: $new-primary;
    .checklist-menu__ico {
      color: $new-primary;
    }
  }
  &_disabled {
    color: #E5E8EF;
    cursor: not-allowed;
    .checklist-menu__ico {
      color: #E5E8EF;
    }
  }
}

</style>
