import {REQUIRED} from "@/util/validation-rules"
import {DATE_DEFAULT_REGXP} from "@/util/const"

export default class CourseJourneyValidator {
  constructor(data, validator) {
    this.data = data;
    this.validator = validator;
  }

  validate() {
    this.validator.resetFields();
    return this.isValid();
  }

  isValid() {
    this.validateRequired('comment', this.data.comment)
    return this.validator.isValid();
  }


  validateRequired(field, data) {
    let match = REQUIRED(data);
    if (match === true) return true;
    this.validator.invalidField(field,  'You cannot proceed with an empty field. Please add comment.');
    return false;
  }
}
