<template lang="pug">
v-text-field(
  dense
  :filled="filled"
  :value="value"
  :clearable="clearable"
  @input="$emit('input', $event)"
  @change="$emit('change', $event)"
  @focus="$emit('focus', $event)"
  @blur="$emit('blur', $event)"
  @click="$emit('click', $event)"
  @click:append="$emit('click:append', $event)"
  @click:clear="$emit('click:clear', $event)"
  :placeholder="placeholder"
  :label="label"
  :error="error"
  :error-messages="errorMessages"
  :data-test="dataTest"
  :class="{'input_v2': v2}"
  ref="input"
).input
  template(v-slot:append)
    slot(name="append")
    
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    placeholder: String,
    dataTest: String,
    clearable: Boolean,
    error: Boolean,
    errorMessages: String,
    hideDetails: Boolean,
    label: String,
    filled: {
      default: true,
      type: Boolean
    },
    v2: Boolean
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_input";

</style>